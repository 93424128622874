@font-face {
    font-family: title;
    src: url(fonts/leaguespartan-bold.ttf);
}
@font-face {
    font-family: main;
    src: url(fonts/leaguespartan-bold.ttf);
    /*src: url(fonts/NixieOne-Regular.otf);*/
}

body {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #403343 20%, #4D3D51 100%);
  color: white;
  padding-top: 10vh;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.banner {
    text-align: center;
}

.logo {
  height: 90px;
  width: 90px;
  display:inline-block;
}

h1 {
    font-family: title;
    font-size: 7rem;
    padding-left: 15px;
    display: inline-block;
}

h2 {
    font-family: title;
    font-size: 5rem;
}
h3 {
   font-weight: 100;
   font-family: main;
}

p {
    font-weight: 100;
    font-size: 1.1rem;
    line-height: 2;
}

p.justify {
    text-align: justify;
}

p.list {
    margin-left: 30px;
}

a { color: white; text-decoration: underline; }
.white a { color: #4D3D51; text-decoration: underline; }
a:hover { color: white; text-decoration: underline; }
.white a:hover { color: #4D3D51; text-decoration: underline; }

@media (max-width: 620px) {
    .logo { height: 60px; width: 60px; }
    h1 { font-size: 2rem; display: block; }
    p { font-size: 0.8rem; }

}

strong {
    font-weight: bold;
}

a { cursor: pointer; }
u {
    border: solid 1px #4D3D51;
    text-decoration: none;
    border-radius: 3px;
    padding: 3px 5px;
}

u:hover {
    background-color: #4D3D51;
    color: white;
}

s { text-decoration: none; font-size: 0.8rem; }
.animate { opacity: 0.5; }
.animate:hover { opacity: 1; }

img.go-back {
    height: 50px;
    position: fixed!important;
    left: 40px;
    top: 50vh;
    z-index: 100;
}

img.go-front {
    height: 50px;
    position: fixed!important;
    right: 40px;
    top: 50vh;
    z-index: 100;
}

.full-img {
    width: 100%;
}

.disclaimer {
    margin: 30px 30px;
    border: solid 1px #4D3D51;
    border-radius: 3px;
    padding: 20px 30px;
}

.separator {
    border-bottom: solid 1px #2b2f3c;
    margin-left: 30%;
    width: 40%;
    height: 1px;
    padding-top: 10px;
}

.center-cyrcle { margin: 10px 5px 10px 40%; }

.question-cyrcle {
    height: 15px;
    padding-right: 5px;
    float: left;
}

.hidden {display: none;}

.box {
    padding: 70px 15vw;
    margin: 5vh auto 0 auto;
    width: 100%;
}

.normal {
    text-align: left;
}

.img-box {
    padding: 0 3vw;
    margin-top: -70px;
}

.img-box img {
    width: 100%;
    border: solid 1px white;
    transition: transform .2s;
    position: relative;
    z-index: 1000;
}

.img-box img:hover {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.5) translate(25%, -6%);
    transform: translateZ(0) scale(1.5) translate(25%, -6%);
}

.white {
    background: white;
    color: #2b2f3c;
}

.warning {
    color: red;
    padding-top: 10px;
    font-size: 1rem;
}

.show-more-cb, .show-more { display: none; }
.show-more-cb ~ label { cursor: pointer; }
.show-more-cb ~ label p { display: inline-block; margin-bottom: 20px; }
.show-more-cb ~ label:before { content: '+'; margin-right: 10px; }
.show-more-cb:checked ~ .show-more { display: block; }
.show-more-cb:checked ~ label:before { content: '-'; }


/* TextInput */
/*input[type=text] { height: 30px; font-weight:100; border: none; text-align: center; margin-top: 13px; }*/
/*input[type=text] { background: url('img/edit.svg') no-repeat right; padding-right: 40px; padding-bottom: 3px; }*/
/*input[type=text]:focus { outline: none !important; }*/

@media (max-width: 620px) {
    .email-box { padding: 30px 10px; }
    input[type=text] { background: none; padding-right: 5px ;}
}

/* The ribbons */

.corner-ribbon{
    font-weight: bold;
  width: 400px;
  background: white;
  position: absolute;
  top: 50px;
  left: -130px;
  color: black;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.social i {
    font-size: 28px;
    color: white;
}

/* Mouse */

.scroll-downs {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width :34px;
  height: 55px;
}
.mousey {
  margin: 4vh 0;
  width: 3px;
  padding: 10px 10px;
  height: 25px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 3px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}
